@import "../variables";

nav ul li,
nav ul li a {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

nav summary {
  font-family: 'Bungee', sans-serif;
}

nav summary + ul li {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

nav details li a {
  font-size: 0.7em;
}

details[role="list"] summary + ul li a:hover {
  color: $primary-500;
  font-weight: 700;
}
