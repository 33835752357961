// Light Blue
$primary-hue: 195 !default;
$primary-50: hsl($primary-hue, 90%, 94%) !default;
$primary-100: hsl($primary-hue, 88%, 86%) !default;
$primary-200: hsl($primary-hue, 86%, 77%) !default;
$primary-300: hsl($primary-hue, 84%, 68%) !default;
$primary-400: hsl($primary-hue, 82%, 59%) !default;
$primary-500: hsl($primary-hue, 80%, 50%) !default;
$primary-600: hsl($primary-hue, 85%, 41%) !default;
$primary-700: hsl($primary-hue, 90%, 32%) !default;
$primary-800: hsl($primary-hue, 95%, 23%) !default;
$primary-900: hsl($primary-hue, 100%, 15%) !default;
